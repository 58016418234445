.home {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 0px 10px 100px 10px;
}

.home > div {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
}

.home.macos.fullscreen,
.home.macos {
  padding-top: 20px;
}

.home.fullscreen {
    padding-top: 90px;
}

.home .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    box-sizing: border-box;
}

.home .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    opacity: 0.5;

    position: absolute;
    bottom: 20%;
    left: calc(50% - 25px);
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.home.searchFocused {
    overflow: hidden;
    height: 100vh;
}

.home.searchFocused header {
    padding-top: 50px;
}

.home header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 998;
    padding: 40px 0px 10px 0px;
    box-sizing: border-box;
}

.home header .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    user-select: none;
    position: relative;
}

.home header .more {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.home header .more svg {
    position: relative;
    top: 3px;
    transition: transform 0.3s ease-in-out;
}

.home header .more.moreOpen svg {
    transform: rotate(180deg);
}

.home header .tag {
    flex: 1 0 auto;
    text-align: center;
    font-size: 0.9em;
    height: 27px;
    max-height: 27px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 5px 20px;
    cursor: pointer;
    line-height: 20px;
}

.home .searchTag {
    border-radius: 5px;
    margin: 2px 0;
}

.home .searchTag.searchTagActive span {
    color: #000;
}

.home .searchTag.searchTagActive,
.home .tag.tagActive {
    background-color: #FFC107;
    color: #000;
}

.home .tag {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 2px;
    padding: 3px 10px;
    font-size: 0.7em;
}

.home .back {
    width: 100px;
    height: 100%;
    background-color: transparent;
    border: 0;
    color: rgba(255, 255, 255, 1);
    font-size: 0.8em;
    cursor: pointer;
    padding: 30px 30px 30px 40px ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home .title {
    width: 100%;
    font-size: 1em;
    text-align: center;
}

.home .searchPanel {
    position: relative;
    width: 100%;
    display: flex;
    padding-top: 5px;
}

.home .searchInputWrapper {
    position: relative;
    width: 100%;
}

.home .searchIcon {
    font-size: 1.2em;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.home .searchPanelFocused {
    position: relative;
    z-index: 9999;
}

.home .searchPanel input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 0;
    padding: 0 10px 0 40px;
    background: linear-gradient(to right, #142E31, #18393a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.home .characters {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    box-sizing: border-box;
    gap: 10px;
}

.home .character,
.home .emptyCharacter {
    position: relative;
    width: 100%;
    max-width: 200px;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px;
    box-sizing: border-box;
}

.home .emptyCharacter {
    animation: pulse 2s linear infinite;
}

.home .emptyCharacter .name,
.home .emptyCharacter .description {
    display: block;
    width: 100%;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.1);
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.home .emptyCharacter .tag {
    min-width: 70px;
}

.home .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 0px;
    z-index: 999;
    background-color: transparent;
    padding: 10px 0;
    box-sizing: border-box;
}

.home .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.home .avatar {
    width: 100%;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.home .visability {
    position: absolute;
    top: 10px;
    left: 10px;
}

.home .name {
    font-size: 1rem;
    display: block;
    margin: 5px 0;
}

.home .description {
    display: block;
    font-size: 0.8em;
    text-overflow: ellipsis;
    overflow: hidden;
}

.home .pagination {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 10px;
    padding: 20px 20px;
}

.home .next, .home .prev {
    height: 40px;
    border-radius: 5px;
    border: 0;
    background-color: rgb(22, 45, 51);
    color: rgba(255, 255, 255, 1);
    font-size: 1em;
    cursor: pointer;
}

.home .next:disabled, .home .prev:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.home .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9rem;
}

.home .stats div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.home .stats div span {
    margin-left: 5px;
}

.home .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 0.85rem;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 5px 0;
}

.home .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
}

.home .tab.tabActive {
    background-color: rgba(255, 255, 255, 0.2);
}

.home .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.home .tabs div span {
    margin-left: 5px;
}

.home .searchCharactersResults {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.home .searchCharactersResultsTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.home .searchTagsResults {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}
.home .searchTagsResultsTitle {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.home .overlay.searchFocused {
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px;
}

.home .overlay.searchFocused .searchContainer {
    width: 100%;
    max-width: 850px;
    padding: 60px 50px;
}

@media (min-width: 600px) {
    .home .overlay.searchFocused .searchContainer {
        padding: 60px 0px;
    }
    .home.searchFocused .overlay {
        padding: 0px 50px 100px 50px !important;
    }
}

.home .overlay.searchFocused .searchCloseIcon {
    display: block;
}

.home .searchCloseIcon {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.5rem;
}

.home .overlay.searchFocused .searchResults {
    display: flex;
}

.home .searchResults {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0px 10px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.home .searchCharacter, .home .searchTag {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
}

.home .searchCharacter .avatar {
    width: 30px;
    height: 30px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background-size: cover;
    margin: 0 10px 0 0;
}

.home .highlight {
    color: #FFC107;
}

.home .footer {
    z-index: 9999;
    width: 100%;
    max-height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.home .footerTabs {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 850px;
}

.home .footerTabs button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.home .footerTabs button:active {
    opacity: 0.5;
}

.home .footerTabs button svg {
    font-size: 1.5rem;
    color: #FFF;
}

.home .browsingLevel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 5px 0;
}

.home .browsingLevel .browsingLevelItem {
    flex: 1 1 auto;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.05);
    text-align: center;
    font-size: 0.8rem;
    overflow: hidden;
}

.home .browsingLevel .browsingLevelItem.active {
    background-color: rgba(255, 255, 255, 0.1);
}

.home .browsingLevel .browsingLevelItem:nth-child(2n) {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.home .browsingLevel .browsingLevelItem:first-child {
    border-radius: 5px 0 0 5px;
}

.home .browsingLevel .browsingLevelItem:last-child {
    border-radius: 0 5px 5px 0;
    border-right: none;
}

.home .browsingLevelItemIcon {
    position: relative;
    top: 2px;
}

.home .feed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 850px;
    padding: 20px 0;
    box-sizing: border-box;
    gap: 10px;
}

@media screen and (max-width: 600px) {
    .home .feed {
        flex-direction: column;
    }
}

.mainTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    max-width: 850px;
    min-height: 33px;
    box-sizing: border-box;
    margin: 5px 0;
    overflow: hidden;
}

.mainTab {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
    color: #ffffff7e;
}

.mainTab.mainTabActive {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.mainTabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.home .earnButton {
    position: relative;
}

.home .earnButton svg {
    height: 34px;
    width: 34px;
}

.home .earnButton label {
    background-color: #FFC107;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 25px;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 999;
}

.filterButton:hover {
}

.filterActive {
  color: #FFC107;
}

.filterBadge {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #007AFF;
}

.filtersContainer {
  animation: slideDown 0.3s ease-out;
  width: 100%;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}