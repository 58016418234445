.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
    text-align: center;
}

.spinner {
    animation: spin 2s linear infinite;
    color: var(--theme-text-color);
    margin-bottom: 16px;
}

.success {
    color: #4CAF50;
    margin-bottom: 16px;
}

.container h3 {
    margin: 0 0 8px 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--theme-text-color);
}

.container p {
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} 