.feed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    box-sizing: border-box;
}

.feed .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    opacity: 0.5;

    position: absolute;
    bottom: 20%;
    left: calc(50% - 25px);
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.feed .tags {
    margin: 10px 0;
}

.feed .tag {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 2px;
    padding: 3px 10px;
    font-size: 0.7em;
}

.feed .tag.tagActive {
    background-color: #FFC107;
    color: #000;
}

.feed header .more {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.feed header .more svg {
    position: relative;
    top: 3px;
    transition: transform 0.3s ease-in-out;
}

.feed header .more.moreOpen svg {
    transform: rotate(180deg);
}

.feed .browsingLevel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.feed .browsingLevel .browsingLevelItem {
    flex: 1 1 auto;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.05);
    text-align: center;
    font-size: 0.8rem;
    overflow: hidden;
}

.feed .browsingLevel .browsingLevelItem.active {
    background-color: rgba(255, 255, 255, 0.1);
}

.feed .browsingLevel .browsingLevelItem:nth-child(2n) {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.feed .browsingLevel .browsingLevelItem:first-child {
    border-radius: 5px 0 0 5px;
}

.feed .browsingLevel .browsingLevelItem:last-child {
    border-radius: 0 5px 5px 0;
    border-right: none;
}

.feed .browsingLevelItemIcon {
    position: relative;
    top: 2px;
}

.feed .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 0.9rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
}

.feed .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
}

.feed .tab.tabActive {
    background-color: rgba(255, 255, 255, 0.2);
}

.feed .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.feed .tabs div span {
    margin-left: 5px;
}

.feed header {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 998;
    box-sizing: border-box;
    padding: 0;
}

.feed header .tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    user-select: none;
    margin-top: 20px;
    position: relative;
}

.feed header .more {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.feed header .more svg {
    position: relative;
    top: 3px;
    transition: transform 0.3s ease-in-out;
}

.feed header .more.moreOpen svg {
    transform: rotate(180deg);
}

.feed header .tag {
    flex: 1 0 auto;
    text-align: center;
    font-size: 0.9em;
    height: 27px;
    max-height: 27px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 5px 20px;
    cursor: pointer;
    line-height: 20px;
}

.feed .loader {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    min-width: 1px;
    min-height: 1px;
}

.feed .emptyPost {
    width: 100%;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    animation: pulse 1s linear infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}