@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.root {
  font-family: "Rubik", sans-serif;
  font-weight: 100;
}

button {
    font-family: "Rubik", sans-serif;
    font-weight: 200;
}

input, textarea {
    font-family: "Rubik", sans-serif;
    font-weight: 100;
}