.chatContainer {
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    transition: height 0.3s ease-in-out;
}

.chatContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.chatContainer.ios .chatContent {
    height: calc(var(--height) + var(--tg-safe-area-inset-bottom));
}

.characterInfo {
    position: fixed;
    top: 0;
    right: -400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.64);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 80px 20px 120px 20px;
    box-sizing: border-box;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    overflow-y: auto;
    z-index: 9999;
    transition: right 0.3s ease-in-out;
}

.chatContainer.ios .characterInfo,
.chatContainer.android .characterInfo {
    padding: calc(var(--tg-safe-area-inset-top) + 20px) calc(var(--tg-safe-area-inset-right) + 10px) calc(var(--tg-safe-area-inset-bottom) + 90px) calc(var(--tg-safe-area-inset-left) + 10px);
}

.characterInfo.visible {
    right: 0;
}

.characterInfoSection h3 {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 8px;
}

.characterInfoTags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.characterInfoTag {
    background: rgba(255, 255, 255, 0.1);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
}

.characterInfoTag a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.characterInfoStats {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 12px;
    min-height: 60px;
    flex-shrink: 0;
}

.characterInfoStat {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.characterInfoStatValue {
    font-size: 1rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}

.characterInfoStatLabel {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
}

.characterInfoAuthor {
    text-align: center;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 8px;
    min-height: 20px;
    flex-shrink: 0;
}

.characterInfoAvatar {
    width: 200px;
    height: 200px;
    min-height: 200px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0 auto;
    flex-shrink: 0;
    margin-bottom: 16px;
}

.characterInfoAvatar > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.characterInfoAvatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.characterInfoName {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: #fff;
    min-height: 32px;
    flex-shrink: 0;
}

.characterInfoDescription {
    font-size: 0.9rem;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.8);
    white-space: pre-wrap;
}

/* .chatContainer.macos .header {
    padding-top: 15px;
} */

/* .chatContainer.ios .footer,
.chatContainer.android .footer {
    padding-bottom: 25px;
}

.messagesContainer.ios,
.messagesContainer.android {
    padding-bottom: 120px;
}

.messagesContainer.macos.fullscreen,
.messagesContainer.macos {
    padding-top: 100px;
}

.messagesContainer.fullscreen {
    padding-top: 125px;
} */

.messagesContainer {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    overflow-x: hidden;
    overflow-anchor: none !important;
    scrollbar-gutter: stable;
    will-change: transform;
    overscroll-behavior-y: none;
    contain: style layout;
    transform: translateZ(0);
    
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
    block-size: auto;
    box-sizing: border-box;
    scroll-behavior: auto !important;
    padding: 40px 10px;
}

.messagesList {
    display: flex;
    flex-direction: column-reverse;
    contain: layout style paint;
    overflow-anchor: none;
    gap: 10px;
    padding-top: 40px;
}

.messagesContainer.ios,
.messagesContainer.android {
    padding: 120px 5px 40px 5px;
}

.chatContent::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.8;
    z-index: -1;
    background-image: var(--background-image);
    filter: blur(5px);
}

.chatContent::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
}

.message {
    position: relative;
    user-select: none;
    display: flex;
    width: 90%;
    max-width: 500px;
    gap: 8px;
    font-weight: 400;
    color: #000;
    contain: layout style paint;
    height: auto;
    min-height: 40px;
}

.message.GAME_MASTER .messageContent:before {
    display: none;
}


.message.GAME_MASTER p {
    margin: 5px 0;
}

.message * {
    user-select: none;
}

.message b {
    font-weight: 500;
}

.message p {
    margin: 0;
}

.message p em {
    color: #434343;
}

.userMessage {
    margin-left: auto;
    flex-direction: row-reverse;
    width: 80%;
}

.botMessage {
    margin-right: auto;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
}

.avatar > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.messageContent {
    background: #FFF;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    padding: 8px 12px;
    border-radius: 12px;
    position: relative;
    font-size: 0.9rem;
    color: #000000;
    margin: 0 8px;
}

.messageContent p:first-child {
    margin: 0;
}

.messageContent i {
    color: #2f2f2f;
}

.userMessage .messageContent {
    background: rgba(225, 255, 199, 1);
}

.characterName {
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 0.8rem;
    opacity: 0.8;
}

.characterName.user {
    text-align: right;
}

.messageText {
    line-height: 1.4;
    word-break: break-word;
}

.messageTime {
    font-size: 0.7rem;
    opacity: 0.6;
    text-align: right;
}

.header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(to right, #142E31, #18373a);
    z-index: 9999;
    padding: 10px 110px 10px 110px;
}

.chatContainer.ios .header,
.chatContainer.android .header {
    display: none;
}

/* .chatContainer.ios .header,
.chatContainer.android .header {
    padding-bottom: 20px;
}

.chatContainer.ios .characterInfo,
.chatContainer.android .characterInfo {
    padding: 100px 20px;
} */

/* .chatContainer.ios .header .avatar,
.chatContainer.android .header .avatar {
    position: absolute;
    top: 100px;
    right: 20px;
    margin-right: 0;
    width: 42px;
    height: 42px;
} */

.header .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 12px;
}

.header .title {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.footer {
    position: relative;
    display: flex;
    padding-bottom: calc(var(--tg-safe-area-inset-bottom));
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #142E31, #18373a);
    z-index: 9999;
}

/* .chatContainer.ios .footer {
    height: 58px;
} */

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    width: 100%;
    max-width: 850px;
}

.input {
    flex: 1;
    background: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 21px;
    padding: 8px 35px 12px 50px;
    max-height: 120px;
    min-height: 40px;
    overflow-y: auto;
    resize: none;
    font-family: inherit;
    color: #000000;
    font-size: 0.9rem;
    white-space: pre-wrap;
    word-break: break-word;
    direction: ltr;
    unicode-bidi: plaintext;
}

.input::placeholder {
    color: rgba(0, 0, 0, 0.4)
}

.input:focus {
    outline: none;
}

.sendButton {
    background: none;
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.sendButton svg {
    position: relative;
    left: 2px;
}

.sendButton:hover {
    opacity: 0.9;
    background: none;
}

.sendButton:active {
    opacity: 0.8;
    background: none;
}

.sendButton:disabled {
    opacity: 0;
    cursor: not-allowed;
}

.loading {
    text-align: center;
    padding: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
}

.error {
    text-align: center;
    padding: 12px;
    color: #ff4d4f;
    font-size: 0.9rem;
}

.messageContent::before {
    content: '';
    position: absolute;
    top: 8px;
    width: 20px;
    height: 20px;
    background: inherit;
    clip-path: path('M20 20C20 10 10 0 0 0L20 0Z');
}

.botMessage .messageContent::before {
    left: -15px;
    transform: rotate(90deg) scaleX(-1);
}

.userMessage .messageContent::before {
    right: -15px;
    transform: rotate(-90deg);
}

.header .avatar,
.header .title {
    z-index: 9999;
    cursor: pointer;
    transition: opacity 0.2s;
}

.header .avatar:hover,
.header .title:hover {
    opacity: 0.8;
}

.closeButton {
    position: absolute;
    top: 80px;
    right: 20px;
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
}

.ios .closeButton {
    top: 20px;
}

.closeButton:hover {
    background: rgba(255, 255, 255, 0.1);
}

/* @media (max-width: 1199px) {
    .characterInfo {
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
    }

    .characterInfo.visible {
        transform: translateX(0);
    }
}  */

.characterInfoSection {
    min-height: fit-content;
    flex-shrink: 0;
}

@media (max-width: 600px) {
    .characterInfo {
        width: 100%;
        right: -100%;
    }
}

@media (min-width: 1200px) {
    .chatContainer {
        padding-right: 400px;
    }
    
    .characterInfo {
        right: 0;
        display: flex;
    }

    .closeButton {
        display: none;
    }
}

.typing .messageContent {
    min-width: 60px;
    max-width: 100px;
    color: rgba(0, 0, 0, 0.4);
}

.menuButton {
    user-select: none;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 20px;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    background-color: #104248;
}

.menuIcon {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
}

.menuIconBar,
.menuIconBar::before,
.menuIconBar::after {
    position: absolute;
    width: 20px;
    height: 2px;
    background: #FFF;
    border-radius: 2px;
    transition: all 0.3s ease;
}

.menuIconBar {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.menuIconBar::before,
.menuIconBar::after {
    content: '';
    left: 0;
}

.menuIconBar::before {
    top: -6px;
}

.menuIconBar::after {
    bottom: -6px;
}

.menuButton.active .menuIconBar {
    background: transparent;
}

.menuButton.active .menuIconBar::before {
    top: 0;
    transform: rotate(45deg);
}

.menuButton.active .menuIconBar::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.commandsMenu {
    position: absolute;
    bottom: 100%;
    left: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
}

.commandButton {
    width: 100%;
    padding: 12px;
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    text-align: left;
}

.commandButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.commandText {
    font-weight: bold;
    color: #104248;
    margin-bottom: 4px;
}

.commandDescription {
    font-size: 0.9em;
    color: #666;
}

.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.picConfirm {
    padding: 16px;
}

.picConfirmText {
    color: #104248;
    margin-bottom: 16px;
    line-height: 1.4;
}

.picConfirmButtons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.picConfirmButton {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.picConfirmButton:first-child {
    background-color: #e0e0e0;
    color: #104248;
    margin-right: auto;
}

.picConfirmButton:nth-child(2) {
    background-color: #104248;
    color: white;
}

.picConfirmButton:last-child {
    background-color: #e0e0e0;
    color: #104248;
}

.picConfirmButton:hover {
    opacity: 0.9;
}

.imageGeneration {
    padding: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
}

.imageGenerationStatus {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    color: #104248;
    font-weight: 500;
}

.imageGenerationSpinner {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border: 3px solid rgba(16, 66, 72, 0.2);
    border-top-color: #104248;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    box-sizing: border-box;
}

.imageGenerationStatusText {
    max-width: 150px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.imageGenerationParams {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    font-size: 0.8rem;
    color: #666;
}

.imageGenerationParams span {
    background: rgba(16, 66, 72, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
}

.imageGenerationResult {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.generatedImage {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
}

.generatedImage:hover {
    transform: scale(1.02);
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.lightboxContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lightboxContent > div {
    width: 100%;
    height: 100%;
}

.lightboxContent img {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain;
}

.lightboxClose {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 10000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightboxControls {
    position: absolute;
    top: 60px;
    right: 20px;
    display: flex;
    gap: 12px;
    z-index: 10000;
}

.chatContainer.ios .lightboxControls,
.chatContainer.android .lightboxControls {
    top: 20px;
}

.lightboxButton {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.blurred {
    filter: blur(20px);
    transition: filter 0.3s ease;
}

.nsfwWarning {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    pointer-events: none;
}

.imageGenerationResult {
    position: relative;
    cursor: pointer;
}

.systemMessage {
    display: flex;
    justify-content: center;
    margin: 8px 0;
}

.systemMessage .messageContent {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 80%;
}

.systemMessage .messageText {
    color: #666;
    font-size: 0.9em;
    text-align: center;
}

.systemMessage .messageTime {
    text-align: center;
    font-size: 0.8em;
    color: #999;
    margin-top: 4px;
}

.buyCreditsMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    color: #104248;
}

.systemMessage .messageContent::before {
    display: none;
}

.buyCreditsTitle {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 8px;
}

.buyCreditsDescription {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 16px;
    text-align: center;
}

.creditPacks {
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
}

.creditPack {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    background: #104248;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: transform 0.2s;
    min-width: 80px;
}

.creditPack:hover {
    transform: scale(1.05);
}

.creditAmount {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 4px;
}

.creditPrice {
    font-size: 0.9em;
    opacity: 0.9;
}

.input :global(.ProseMirror) {
    outline: none;
    width: 100%;
    min-height: 40px;
    max-height: 120px;
    overflow-y: auto;
    word-break: break-word;
}

.input :global(.ProseMirror p) {
    margin: 0;
}

.contextMenu {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px 0;
    min-width: 150px;
    max-width: calc(100vw - 24px);
    z-index: 1000;
    transition: all 0.1s ease-out;
}

.contextMenuItem {
    display: block;
    width: 100%;
    padding: 8px 16px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding-left: 30px;
}

.contextMenuItem svg {
    position: absolute;
    top: 10px;
    left: 10px;
}

.contextMenuItem {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.contextMenuItem:first-child {
    border-top: none;
}

.contextMenuItem:hover {
    background-color: #f5f5f5;
}

.contextMenuItem:active {
    background-color: #e5e5e5;
}

/* .hasActiveMenu .messagesContainer::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 900;
    pointer-events: none;
} */

.activeMessage {
    position: relative;
    z-index: 1000;
}

.hasActiveMenu .messagesContainer::before {
    transition: all 0.2s ease-out;
}

/* .hasActiveMenu .header,
.hasActiveMenu .footer {
    filter: blur(4px);
    pointer-events: none;
} */

.activeMessage {
    transform: scale(1.01);
    transition: transform 0.2s ease-out;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.hasActiveMenu .message:not(.activeMessage) {
    pointer-events: none;
}

.actions {
    position: absolute;
    top: 120px;
    left: -10px;
    z-index: 666;
    width: 100%;
    right: 0px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.chatContainer.ios .actions,
.chatContainer.android .actions {
    top: 60px;
}


.edit,
.share {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    right: 0px;
}

.edit button,
.share button {
    width: 70px;
    font-size: 1.2rem;
    margin-top: 0;
    padding: 10px 0;
}

.button {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 15px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.loadingMore {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    margin: 8px 0;
    gap: 8px;
    font-size: 14px;
    text-align: center;
    opacity: 0.7;
    
    /* Предотвращает скачки при появлении/исчезновении этого элемента */
    contain: size layout;
    height: 52px;
}

.loadingSpinner {
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff00;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.messagesContainerEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
}

.messagesContainerEmpty .loadingSpinner {
    margin-top: 20px;
}

.imageActions {
    position: absolute;
    bottom: -30px;
    left: -10px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 12px;
}

.actionButton {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: var(--tg-theme-text-color);
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 0.9rem;
}

.actionButton:hover {
    background: rgba(255, 255, 255, 0.2);
}

.createPostForm {
    margin-top: 16px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
}

.postInput {
    width: 100%;
    min-height: 80px;
    background: var(--tg-theme-bg-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px;
    color: var(--tg-theme-text-color);
    resize: vertical;
    margin-bottom: 12px;
}

.postActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.charCount {
    color: var(--tg-theme-hint-color);
    font-size: 0.9rem;
}

.cancelButton {
    background: none;
    border: none;
    color: var(--tg-theme-hint-color);
    margin-right: 12px;
    cursor: pointer;
    padding: 8px 16px;
}

.submitButton {
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
}

.submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.selectionOverlay {
    position: absolute;
    top: 20px;
    right: -15px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid var(--tg-theme-button-color);
    background: var(--tg-theme-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.selected .selectionOverlay .selectionCheckmark {
    background: var(--tg-theme-button-color);
}

.selectionCheckmark {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--tg-theme-button-text-color);
    transition: opacity 0.2s;
    color: var(--theme-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectionCheckmark svg {
    opacity: 0;
}

.selected .selectionCheckmark svg {
    opacity: 1;
}

.selectionControls {
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    padding: 16px;
    background: var(--theme-bg-color);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.ios .selectionControls,
.android .selectionControls {
    top: 0px;
}

.selectionControls button{
    width: auto;
    font-size: 0.7rem;
    padding: 10px 16px;
    margin-top: 0px;
}

.selectionCount {
    color: var(--theme-text-color);
    font-size: 1rem;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
    font-family: "Rubik", sans-serif;
}

.modal {
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border-radius: 16px 16px 0 0;
    width: 100%;
    padding: 16px 16px calc(16px + var(--tg-safe-area-inset-bottom)) 16px;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    animation: slideUp 0.3s forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modalHeader h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--theme-text-color);
}

.closeButton {
    background: none;
    border: none;
    color: var(--theme-text-color);
    cursor: pointer;
    padding: 4px;
    margin: 0;
}

.modalContent {
    position: relative;
}

.postInput {
    width: 100%;
    min-height: 120px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.05);
    color: var(--theme-text-color);
    resize: vertical;
    font-size: 1rem;
    font-family: inherit;
}

.postInput:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.2);
}

.modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.charCount {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
}

.submitButton {
    background: rgba(255, 255, 255, 0.1);
    color: var(--theme-text-color);
    border: none;
    border-radius: 20px;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.submitButton:active {
    background: rgba(255, 255, 255, 0.2);
}

.submitButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.promptInput {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.promptInputText {
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
}

.promptInput input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    width: 100%;
}

.promptButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    gap: 4px;
}

.backButton {
    padding: 8px 16px;
    background-color: #e0e0e0;
    color: #104248;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.backButton:hover {
    opacity: 0.9;
}

.imageGenerationError {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e53935;
  text-align: center;
  padding: 12px;
  background-color: rgba(229, 57, 53, 0.1);
  border-radius: 8px;
  margin: 10px 0;
}

.errorIcon {
  font-size: 20px;
  margin-right: 8px;
  flex-shrink: 0;
}

.picOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  gap: 20px;
}

.picOptionSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.picOptionRow {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.picOptionLabel {
  font-weight: 500;
  color: #333;
  font-size: 13px;
  margin-bottom: 2px;
}

.toggleGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
}

.toggleButton {
  padding: 8px 0;
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid #ddd;
  background: #f5f5f5;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.toggleButton.active {
  background: #104248;
  color: white;
  border-color: #104248;
}

.picConfirmButton {
  padding: 10px 16px;
  background-color: #104248;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-weight: 500;
}

.picConfirmButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Дополнительные стили контейнера для сохранения позиции скролла */
.messagesContainer::-webkit-scrollbar {
    width: 8px;
}

.messagesContainer::-webkit-scrollbar-track {
    background: transparent;
}

.messagesContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.scrollDownButton {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: rgba(16, 66, 72, 0.9);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 100;
}

.scrollDownButton.visible {
    opacity: 1;
    transform: translateY(0);
}

.scrollDownButton svg {
    width: 36px;
    height: 36px;
    color: white;
}

.scrollDownButton:active {
    background: rgba(16, 66, 72, 1);
    transform: scale(0.95);
}