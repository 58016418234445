.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
    font-family: "Rubik", sans-serif;
    height: calc(var(--tg-viewport-stable-height) + var(--tg-safe-area-inset-bottom));
}

.modal {
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border-radius: 16px 16px 0 0;
    width: 100%;
    padding: 16px;
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
    animation: slideUp 0.3s forwards;
    max-width: 650px;
    box-sizing: border-box;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modalHeader h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--theme-text-color);
}

.closeButton {
    background: none;
    border: none;
    color: var(--theme-text-color);
    cursor: pointer;
    padding: 4px;
    margin: 0;
}

.modalContent {
    position: relative;
}

.postInput {
    width: 100%;
    min-height: 120px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    background: rgba(255, 255, 255, 0.05);
    color: var(--theme-text-color);
    resize: vertical;
    font-size: 1rem;
    font-family: inherit;
    box-sizing: border-box;
}

.postInput:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.2);
}

.modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.charCount {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
}

.submitButton {
    background: rgba(255, 255, 255, 0.1);
    color: var(--theme-text-color);
    border: none;
    border-radius: 20px;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.submitButton:active {
    background: rgba(255, 255, 255, 0.2);
}

.submitButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.emojiBar {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 100%;
    overflow-x: scroll;
}

.emojiButton {
    background: none;
    border: none;
    padding: 4px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.emojiButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
} 