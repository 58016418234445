.show {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    transition: all 30ms;
}

.posts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.follow {
    font-size: 0.9rem;
}

.follow svg {
  margin: 0 5px;
}

.show.macos.fullscreen,
.show.macos {
    padding-top: 20px;
}

.show.macos {
    padding-top: 30px;
}

.show.fullscreen {
    padding-top: 90px;
}

.show.menuOpen {
    overflow: hidden;
    max-height: 100vh;
    height: 100vh;
}

.show .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    top: 50%;
    left: calc(50% - 20px);
    transform: translate(-50%, -50%);
    z-index: 9999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.show .connect.button {
    margin: 0 0 20px 0;
}

.show .container {
    padding: 10px 10px 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.show .field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    box-sizing: border-box;
}

.show .name {
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.show .author {
    font-size: 0.9rem;
}

.show .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-top: 5px;
}

.show .avatar {
    width: 100%;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.show .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    gap: 5px;
    font-size: 1.1rem;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
}

.show .stats div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    border-radius: 5px;
    position: relative;
    flex: 1;
    cursor: pointer;
}

.show .stats div:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.show .stats div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 30px;
    width: 1px;
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
}

.show .stats div span {
    margin-left: 5px;
}

.show .tags {
    margin: 10px 0;
}

.show .tag {
    font-size: 0.9rem;
    line-height: 25px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 25px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px;
    padding: 2px 10px 2px 10px;
}

.show .bio {
    font-size: 0.95rem;
    padding: 0 5px;
    display: block;
}

.show .button {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 15px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.show .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.menu {
    width: 90%;
    max-width: 550px;
    position: absolute;
    z-index: 9999;
    bottom: -100vh;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px 15px 0 0;
    padding: 0px 10px var(--tg-safe-area-inset-bottom) 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: bottom 0.3s ease-in-out;
}

.menu.menuOpen {
    bottom: 0;
}

.menu .menuTitle {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    width: 100%;
    text-align: center;
}

.menu div:last-child .button {
    border-bottom: none;
}

.menu .bot {
    width: 100%;
}

.menu .button {
    width: 100%;
    min-width: 100%;
    border-radius: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    font-size: 1rem;
    margin: 0;
    padding: 20px 0;
}

.button.close {
    border: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
}

.show .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
    min-height: 30px;
}

.show .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
    color: #ffffff7e;
}

.show .tab.active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.show .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.show .tabs div span {
    margin-left: 5px;
}

.show .bots {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.show .bots .bot {
    font-size: 0.9rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
}

.show .bots .botDeletingText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.show .bots .botDeleting .botContent {
    opacity: 0.25;
}

.show .bots .botDeleting .botDeletingOverlay {
    left: 0;
    transition: left 3s ease-in-out;
}

.show .bots .botDeletingText {
    display: none;
}

.show .bots .botDeleting .botDeletingText {
    display: block;
    text-align: center;
    line-height: 70px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.show .bots .botDeletingOverlay {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.2);
}

.show .bots .botOpened {
    background-color: rgba(255, 255, 255, 0.2);
}

.show .bots .botActions {
    position: absolute;
    right: -130px;
    transition: right 0.3s ease-in-out;
    top: 0;
    width: 130px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.show .bots .botActions button {
    margin: 0;
    height: 100%;
    border-radius: 0;
}

.show .bots .botOpened .botActions {
    right: 0;
}

.show .bots .botContent {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transition: left 0.3s ease-in-out;
}

.show .bots .botOpened .botContent {
    position: relative;
    top: 0;
    left: -130px;
    width: 100%;
    height: 100%;
}

.show .bots .bot:first-of-type {
    border-radius: 10px 10px 0 0;
}

.show .bots .bot:last-child {
    border-radius: 0 0 10px 10px;
}

.show .bots .bot:only-of-type {
    border-radius: 10px;
}

.show .bots .bot:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.show .bots .bot:active {
    background-color: rgba(255, 255, 255, 0.05);
}

.show .bots .botHeader,
.show .bots .botFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.show .bots .botHeader div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.show .bots .botHeader div:last-child {
    flex: 1;
    text-align: right;
    margin-left: 10px;
}

.show .bots .botHeader {
    margin-bottom: 15px;
}

.show .characters {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    box-sizing: border-box;
    gap: 10px;
}

.show .character {
    position: relative;
    width: 100%;
    max-width: 200px;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px;
    box-sizing: border-box;
}

.show .recommendedTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 20px 0 30px 0;
}

.show .actions {
    position: absolute;
    top: 20px;
    z-index: 666;
    width: 100%;
    right: 0px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.show .edit,
.show .share {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    right: 0px;
}

.show .edit button,
.show .share button {
    width: 70px;
    font-size: 0.9rem;
    margin-top: 0;
    padding: 10px 0;
}

.ratingContainer {
    position: relative;
    max-width: 100px;
    align-self: flex-start;
}

.isAdult {
    position: absolute;
    top: 50px;
    left: 0px;
    padding: 5px;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 400;
    background-color: rgba(255, 0, 0, 0.4);
    text-align: center;
    vertical-align: middle;
    min-height: 16px;
    max-height: 16px;
}

.isAdult span {
    font-size: 0.7rem;
    font-weight: 400;
    height: 16px;
    line-height: 16px;
    padding: 0;
    padding-top: 1px;
    margin: 0;
    display: inline-block;
    box-sizing: border-box;
    min-height: 16px;
    max-height: 16px;
    overflow: hidden;
}

.rating {
    position: absolute;
    top: 20px;
    left: 0px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: 400;
}

.rating.XXX {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.X {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.R {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.PG {
    background-color: rgba(0, 0, 255, 0.4);
}

.rating.PG_13 {
    background-color: rgba(0, 255, 0, 0.4);
}

.show .moderation_score {
    font-size: 0.9rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.show .moderation_score>div {
    margin: 10px 0;
}

.show .alert {
    color: rgb(221, 255, 0);
}

.show .warning {
    color: rgba(255, 165, 0, 1);
}

.show .danger {
    color: rgba(255, 0, 0, 1);
}

.characterInfoSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.characterInfoSection h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 20px 0 30px 0;
}

.characterInfoTags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.characterInfoTag {
    background: rgba(255, 255, 255, 0.1);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
}

.characterInfoTag a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.modalOverlay {
    max-height: calc(var(--tg-viewport-stable-height) + var(--tg-safe-area-inset-bottom));
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    font-family: "Rubik", sans-serif;
}

.modal {
    background: #0a282c;
    color: var(--theme-text-color);
    border-radius: 16px;
    width: 90%;
    max-width: 600px;
    padding: 16px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.modalHeader h3 {
    margin: 0;
}

.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
    color: var(--theme-text-color)
}

.postInput {
    width: 100%;
    min-height: 120px;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    resize: vertical;
    box-sizing: border-box;
    outline: none;
}

.modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submitButton {
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
}

.submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}