.gender .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
}

.gender .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
}

.gender .tab.active {
    background-color: rgba(255, 255, 255, 0.2);
}

.gender .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
