.comments {
  padding: 12px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--theme-text-color);
}

.commentsList {
  margin-bottom: 8px;
}

.comment {
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 1.4;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.commentAuthor {
  font-weight: 600;
  margin-right: 6px;
  font-size: 0.9rem;
}

.commentText {
    color: var(--theme-text-color);
    font-size: 0.8rem;
}

.commentTextWrapper {
    padding: 4px 8px 0 0;
}

.commentsActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.viewAllButton {
  color: #8e8e8e;
  font-size: 14px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.addCommentButton {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--theme-text-color);
  font-size: 14px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.expandButton {
  background: none;
  border: none;
  padding: 0;
  margin-left: 4px;
  color: #8e8e8e;
  font-size: 14px;
  cursor: pointer;
}

.expandButton:hover {
  color: var(--theme-text-color);
}

.replyButton {
    color: #8e8e8e;
    font-size: 0.8rem;
    font-weight: 300;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin-top: 10px;
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.commentDate {
  color: #8e8e8e;
  font-size: 0.7rem;
}