.connectTgBot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
}

.connectTgBot.macos.fullscreen,
.connectTgBot.macos {
    padding-top: 20px;
}

.connectTgBot.macos {
    padding-top: 30px;
}

.connectTgBot.fullscreen {
    padding-top: 90px;
}

.connectTgBot b {
    font-weight: 400;
}

.connectTgBot .container {
    padding: 10px 20px 110px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
}

.connectTgBot .field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    box-sizing: border-box;
}

.connectTgBot .nameContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.connectTgBot .name {
    font-size: 1.4rem;
}

.connectTgBot .author {
    font-size: 0.9rem;
}

.connectTgBot .avatarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;
}

.connectTgBot .avatar {
    width: 100%;
    max-width: 150px;
    max-height: 150px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.connectTgBot .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    gap: 5px;
    font-size: 1.1rem;
    width: 100%;
    box-sizing: border-box;
}

.connectTgBot .stats div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    border-radius: 5px;
    position: relative;
    flex: 1;
}

.connectTgBot .stats div:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.connectTgBot .stats div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 30px;
    width: 1px;
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
}

.connectTgBot .stats div span {
    margin-left: 5px;
}

.connectTgBot .tags {
    margin: 10px 0;
}

.connectTgBot .tag {
    font-size: 0.9rem;
    line-height: 25px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 25px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px;
    padding: 2px 10px 2px 10px;
}

.connectTgBot .bio {
    font-size: 0.95rem;
    padding: 0 5px;
    display: block;
}

.connectTgBot .button {
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 15px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.connectTgBot .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.connectTgBot .menu {
    position: absolute;
    z-index: 9999;
    bottom: -100vh;
    left: 10px;
    right: 10px;
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 0px 10px 30px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: bottom 0.3s ease-in-out;
}

.connectTgBot .menu.menuOpen {
    bottom: 0;
}

.connectTgBot .menu .menuTitle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    width: 100%;
    text-align: center;
}

.connectTgBot .menu .button:last-child {
    border-bottom: none;
}

.connectTgBot .menu .button {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 1rem;
    margin: 0;
    padding: 20px 0;
}

.connectTgBot .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
}

.connectTgBot ul, .connectTgBot ol {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-position: inside;
}

.connectTgBot ul {
    list-style-type: disc;
    margin-left: 10px;
}

.connectTgBot ol {
    list-style-type: decimal;
}

.connectTgBot ol .button {
    margin: 10px 0;
}

.connectTgBot li {
    margin: 10px 0;
}

.connectTgBot p {
    margin: 10px 0;
}

.connectTgBot .code {
    color: #FFC107;
}

.connectTgBot .connectBotInput {
    width: 100%;
    position: relative;
}

.connectTgBot .connectBotInput button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0;
    margin: 0;
}

.connectTgBot .clipboardIcon {
    font-size: 1.2em;
}

.connectTgBot .connectBotInput input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 0;
    padding: 5px 10px 5px 10px;
    background: linear-gradient(to right, rgba(0, 251, 255, 0.05), rgba(0, 150, 153, 0.1));
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    outline: none;
    font-size: 1rem;
}

.connectTgBot .connectBotInput input.inputError {
    border: 1px solid rgba(255, 0, 0, 1);
}

.connectTgBot .error, .connectTgBot .success {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;

    z-index: 99999;
    color: rgba(255, 255, 255, 1);
    padding: 10px;

    animation: popup 1s;
}

.connectTgBot .error div, .connectTgBot .success div {
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.connectTgBot .error div {
    background-color: rgba(225, 0, 0, 1);
}

.connectTgBot .success div {
    background-color: rgba(0, 180, 0, 1);
}

.connectTgBot .button:disabled, .connectTgBot input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.connectTgBot .connectBotIsPublic {
    margin: 10px 0;
}

.connectTgBot .connectBotIsPublic input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}