.tutorialContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: auto;
}

.highlight {
  position: absolute;
  box-shadow: 0 0 0 4px #FFC107;
  border-radius: 5px;
  pointer-events: none;
  z-index: 10000;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.8);
  }
  50% {
    box-shadow: 0 0 0 6px rgba(255, 193, 7, 0.4);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.8);
  }
}

.tooltip {
  position: fixed;
  background: linear-gradient(to bottom right, #1b3f43, #142E31);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  width: 320px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  z-index: 10001;
  pointer-events: auto;
  transition: all 0.3s ease;
}

.tooltip.center {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.tooltip:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
}

.tooltip.top:after {
  bottom: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #142E31;
}

.tooltip.bottom:after {
  top: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1b3f43;
}

.tooltip.left:after {
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #1b3f43;
}

.tooltip.right:after {
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #1b3f43;
}

/* Добавляем стили для позиционирования стрелки */
.tooltip.top:after,
.tooltip.bottom:after {
  left: var(--arrow-left, 50%);
  transform: translateX(-50%);
}

.tooltip.left:after,
.tooltip.right:after {
  top: var(--arrow-top, 50%);
  transform: translateY(-50%);
}

.tooltipContent {
  text-align: center;
  max-height: calc(90vh - 40px);
  overflow-y: auto;
}

.tooltipContent h3 {
  margin: 0 0 10px;
  color: #FFC107;
  font-size: 1.4rem;
}

.tooltipContent p {
  margin: 0 0 20px;
  line-height: 1.5;
  color: #ffffff;
  font-size: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.nextButton, .skipButton {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  transition: all 0.2s ease;
}

.nextButton {
  background-color: #FFC107;
  color: #000000;
  font-weight: 500;
}

.nextButton svg {
  margin-left: 8px;
}

.nextButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.skipButton {
  opacity: 0.7;
}

.skipButton:hover {
  opacity: 1;
}

.progress {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.dot.active {
  background-color: #FFC107;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  z-index: 10002;
}

.closeButton svg {
  font-size: 24px;
}

@media (max-width: 480px) {
  .tooltip {
    width: calc(80% - 40px);
  }
  
  .tooltipContent h3 {
    font-size: 1.2rem;
  }
  
  .tooltipContent p {
    font-size: 0.9rem;
  }
  
  .nextButton, .skipButton {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
} 