.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile .feed {
    width: 100%;
    max-width: 850px;
}

.profile .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 20px 0;
    padding: 0 5px;
    box-sizing: border-box;
    font-size: 0.9rem;
}

.profile .header .avatar {
    width: 100px;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.4);
    border: 5px solid rgba(255, 255, 255, 0.4);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: 20px;
}

.profile .header .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}

.profile .header .info .name {
    font-size: 1rem;
    font-weight: 400;
}

.profile .header .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}

.profile .header .stats > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.profile .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    margin: 20px 0;
    width: 100%;
}

.profile .actions > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    font-weight: 400;
    width: 100%;
}

.profile .actions .loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-top: 2px solid rgba(255, 255, 255, 1);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.profile .premiumButton {
    background: linear-gradient(45deg, #ffbf00, #ff8a00);
    color: #000 !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 20px;
    width: 100%;
}

.nameSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscriptionBadge {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  height: 16px;
}

.badgeIcon {
  display: flex;
  align-items: center;
}

.badgeLabel {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* Optional: Add a hover state that shows more info */
.subscriptionBadge:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
