:root {
  --theme-button-color: rgba(255, 255, 255, 0.05);
  --theme-button-text-color: rgba(255, 255, 255, 1);
  --theme-text-color: rgba(255, 255, 255, 1);
  --theme-button-secondary-color: #ffbf00;
  --theme-button-secondary-text-color: rgba(0, 0, 0, 1);
  --theme-bg-color: #0a282c;
}

.app {
  width: 100%;
  position: relative;
  z-index: 2;
}

