.edit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
}

.edit.macos.fullscreen,
.edit.macos {
    padding-top: 20px;
}

.edit.macos {
    padding-top: 30px;
}

.edit.fullscreen {
    padding-top: 90px;
}

.edit.keyboardOpen {
    padding-bottom: 200px;
}

.edit .spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.edit .spinnerOverlay span {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}

.edit .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    top: calc(50% + 50px);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.edit .edit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.edit .edit button {
    width: 70px;
    font-size: 0.9rem;
    margin-top: 0;
    padding: 10px 0;
}

.edit button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.edit.macos .edit {
    margin-top: 20px;
}

.edit .container {
    position: relative;
    padding: 10px 10px 170px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
}

.edit .name {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
}

.edit .author {
    font-size: 0.9rem;
}

.edit .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.edit .avatar {
    width: 100%;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.edit .avatarEdit {
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 30px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit .avatar:active {
    opacity: 0.9;
}

.edit .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    gap: 5px;
    font-size: 1.1rem;
    width: 100%;
    box-sizing: border-box;
}

.edit .stats div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    border-radius: 5px;
    position: relative;
    flex: 1;
    cursor: pointer;
}

.edit .stats div:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.edit .stats div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 30px;
    width: 1px;
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
}

.edit .stats div span {
    margin-left: 5px;
}

.edit .bio {
    font-size: 0.95rem;
    padding: 0 5px;
    display: block;
}

.edit .button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.edit .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.edit .menu {
    width: 90%;
    max-width: 550px;
    position: absolute;
    z-index: 9999;
    bottom: -100vh;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px 15px 0 0;
    padding: 0px 10px 30px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: bottom 0.3s ease-in-out;
}

.edit .menu.menuOpen {
    bottom: -10px;
}

.edit .menu .menuTitle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    width: 100%;
    text-align: center;
}

.edit .menu .button:last-child {
    border-bottom: none;
}

.edit .menu .bot {
    width: 100%;
}

.edit .menu .button {
    width: 100% !important;
    min-width: 100% !important;
    border-radius: 0 !important;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: #FFFFFF;
    font-size: 1rem;
    margin: 0;
    padding: 20px 0;
}

.edit .button.close {
    border: none !important;
}

.edit .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.edit .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
}

.edit .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
    color: #ffffff7e;
}

.edit .tab.active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.edit .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.edit .tabs div span {
    margin-left: 5px;
}

.edit .field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0 10px 0;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.edit .counter {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
}

.edit input,
.edit textarea {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 10px 10px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.edit .fakeTextarea {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 5px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.edit .fakeTextarea textarea {
    background: transparent;
    border: none;
    outline: none;
    min-height: 40px;
}

.edit textarea {
    min-height: 100px;
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
}

.edit .text {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 5px;
}

.edit .tags {
    width: 100%;
}

.edit .tag {
    font-size: 0.9rem;
    line-height: 25px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 25px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px;
    padding: 2px 10px 2px 10px;
}

.edit .style {
    margin-bottom: 20px;
}

.edit .tokens span:first-child,
.style span:first-child {
    margin-left: 0;
    background-color: transparent;
    padding: 0;
}

.edit .tokens span,
.style span {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 10px;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    font-size: 0.8em;
}

.edit .tokens span:active,
.style span:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.edit .crop {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.edit .avatarChangeButtons button {
    width: 50%;
    margin-top: 10px;
    padding: 10px 0;
}

.edit .avatarChangeButtons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.edit .moderation_score {
    font-size: 0.9rem;
}

.edit .moderation_score>div {
    margin: 10px 0;
}

.edit .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.edit .error,
.edit .success {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;

    z-index: 99999;
    color: rgba(255, 255, 255, 1);
    padding: 10px;

    animation: popup 1s;
}

@keyframes popup {
    0% {
        transform: translateY(-100%) translateX(-50%);
    }

    100% {
        transform: translateY(0) translateX(-50%);
    }
}

.edit .error div,
.edit .success div {
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.edit .error div {
    background-color: rgba(225, 0, 0, 1);
}

.edit .success div {
    background-color: rgba(0, 180, 0, 1);
}

.edit .alert {
    color: rgb(221, 255, 0);
}

.edit .warning {
    color: rgba(255, 165, 0, 1);
}

.edit .danger {
    color: rgba(255, 0, 0, 1);
}

.edit a {
    color: #FFC107;
    text-decoration: underline;
}

.edit .moderationGuide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7);
}

.edit .moderationGuideContent {
    padding: 10px 10px 170px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
}

.edit .moderationGuideHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.edit .blur {
    filter: blur(10px);
}

.edit .dialogueUser {
    flex-direction: row-reverse;
}

.edit .dialogueCharacter {
    flex-direction: row;
}

.edit .dialogueUser .counter {
    right: 50px;
}

.edit .dialogueUser,
.edit .dialogueCharacter {
    gap: 10px;
}

.edit .dialogueCharacter textarea,
.edit .dialogueUser textarea {
    min-width: auto;
}

.edit .userAvatar {
    background: linear-gradient(135deg, #37aee2, #1e96c8);
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit .characterAvatar {
    background: linear-gradient(135deg, #e2ce37, #c8761e);
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit .dialogue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
}

.edit .addDialogue,
.edit .addMemory {
    padding: 10px 20px 7px 20px;
    margin-top: 10px;
}

.edit .memory {
    width: 100%;
}

.edit .visability {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.edit .visability.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.edit .visability label {
    margin: 0 10px;
}

.edit .switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
}

.edit .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The switcher */
.edit .switcher {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
}

.edit .switcher:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #adb5bd;
    transition: .4s;
}

.edit input:checked+.switcher {
    background-color: #FFC107;
    border: 1px solid #FFC107;
}

.edit input:focus+.switcher {
    box-shadow: 0 0 1px #FFC107;
}

.edit input:checked+.switcher:before {
    transform: translateX(1.4em);
    background-color: #fff;
}

.edit .importContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 10px;
    top: 80px;
    gap: 10px;
    z-index: 1;
}

.edit .importContainer button {
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 5px;
}

.edit .importContainer > button:first-of-type {
    background: linear-gradient(to bottom, #5938E3, #A600FF);
    color: #fff;
}

.edit .importContainer button.import {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: none;
    cursor: pointer;
}

.edit .importOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.edit .importOverlay span,
.edit .importOverlay p {
    color: #fff;
    font-size: 0.8rem;
    margin: 10px 0 0 0;
    text-align: left;
}

/* .edit .importContainer button.import span {
    margin-top: 5px;
} */

.edit .importContainer .importOptions {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.edit .importContainer .importOptions button {
    cursor: pointer;
}

.edit .importContainer .importOptions.importOptionsOpen {
    display: flex;
}

.edit .importContainer .importOptions button {
    width: 100%;
    border-radius: 0;
}

.edit .importContainer .importOptions button:first-child {
    border-radius: 5px 5px 0 0;
}

.edit .importContainer .importOptions button:last-child {
    border-radius: 0 0 5px 5px;
}

.edit .importContainer .importOptions button:only-child {
    border-radius: 5px;
}

.edit .importContainer .importOptions button:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.edit .importUrl {
    max-width: 550px;
    width: 100%;
    position: relative;
}

.edit .importUrl input {
    width: 100%;
    margin-bottom: 10px;
}

.close {
    position: absolute;
    top: calc(var(--tg-content-safe-area-inset-top) + 50px);
    right: 10px;
}

.rating {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: 400;
}

.rating.XXX {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.X {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.R {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.PG {
    background-color: rgba(0, 0, 255, 0.4);
}

.rating.PG_13 {
    background-color: rgba(0, 255, 0, 0.4);
}

.ratingContainer {
    position: relative;
    max-width: 100px;
    align-self: flex-start;
}

.modalField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 550px;
}

.isAdult {
    position: absolute;
    top: 30px;
    left: 0px;
    padding: 5px;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 400;
    background-color: rgba(255, 0, 0, 0.4);
    text-align: center;
    vertical-align: middle;
    min-height: 16px;
    max-height: 16px;
}

.isAdult span {
    font-size: 0.7rem;
    font-weight: 400;
    height: 16px;
    line-height: 16px;
    padding: 0;
    padding-top: 1px;
    margin: 0;
    display: inline-block;
    box-sizing: border-box;
    min-height: 16px;
    max-height: 16px;
    overflow: hidden;
}

.speechStyle {
    padding: 10px 0;
}

.sliderLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.sliderValue {
    font-weight: bold;
    min-width: 30px;
    text-align: right;
}

.slider {
    width: 100%;
    margin: 10px 0;
}

.sliderDescription {
    font-size: 12px;
    color: #666;
    display: block;
    margin-top: 5px;
}