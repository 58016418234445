.earn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
}

.earn .red {
    color: rgba(255, 0, 0, 1);
}

.earn .green {
    color: rgba(0, 255, 0, 1);
}

.earn mark {
    color: #FFC107;
    font-size: 1.2em;
    font-weight: 500;
}

.earn .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    padding: 20px 20px 150px 20px;
    box-sizing: border-box;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.earn .container::-webkit-scrollbar {
    display: none;
}

.earn .footer {
    width: 100%;
    max-height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.earn .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.earn .footerTabs {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 850px;
}

.earn .footerTabs button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.earn .footerTabs button:active {
    opacity: 0.5;
}

.earn .footerTabs button svg {
    font-size: 1.5rem;
    color: #FFF;
}

.date {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.85em;
    margin-top: 5px;
}

.loading {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 280px;
}

.loading > div {
    height: 100%;
}

.empty {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding: 32px 16px;
    font-size: 1.1em;
}

.earn .earnButton {
    position: relative;
}

.earn .earnButton svg {
    height: 34px;
    width: 34px;
}

.earn .earnButton label {
    background-color: #FFC107;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 25px;
}

.earn header p {
    background-color: rgba(255,255,255,0.1);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 0.95em;
    line-height: 1.2em;
    font-weight: 300;
}

.earn .content {
    width: 100%;
}

.earn .activity {
    background-color: rgba(255,255,255,0.1);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.earn .activity h3 {
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.earn .activity a {
    color: var(--theme-secondary-color);
}

.earn .activity p {
    margin: 0;
    margin: 10px 0;
}

.earn .activity .spoiler {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 0.85em;
    font-weight: 300;
    margin: 10px 0;
}

.earn .activity .activityItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.earn .activityItemCredits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    font-weight: 500;
    color: #ffc107;
}

.earn .activityItemCredits span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 3px;
}

.earn .activityStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.earn .activityStatus svg {
    width: 3em;
    height: 3em;
    opacity: 0.1;
}

.earn .activityStatus.completed svg {
    opacity: 1;
    color: #FFC107;
}

.earn .earned {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
}

.earn .earned h2 {
    font-size: 1.2em;
    font-weight: 400;
}

.earn .earned p {
    font-size: 1.5em;
    font-weight: 500;
    color: #FFC107;
    margin: 0;
}


.transactionsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tableWrapper {
    width: 100%;
    overflow-x: auto;
    background: linear-gradient(to right, #142E31, #18373a);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px 16px;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
}

.table th {
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.table tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.table tr:last-child {
    border-bottom: none;
}

.table td {
    font-size: 0.9rem;
}

.status {
    display: inline-flex;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
}

.statusCompleted {
    background-color: rgba(0, 180, 0, 0.2);
    color: #00b400;
}

.statusPending {
    background-color: rgba(255, 193, 7, 0.2);
    color: #FFC107;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0.5rem;
    box-sizing: border-box;
    
}

.paginationButton,
.paginationPage {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.paginationButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.paginationButton:hover:not(:disabled),
.paginationPage:hover:not(.active) {
    background: rgba(255, 255, 255, 0.1);
}

.paginationPages {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
}

.paginationPage {
    min-width: 40px;
    padding: 8px;
    text-align: center;
}

.paginationPage.active {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
    .pagination {
        flex-direction: column;
        width: 100%;
    }

    .paginationPages {
        width: 100%;
    }

    .paginationButton {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .table th,
    .table td {
        padding: 8px 12px;
    }

    .paginationPages {
        gap: 0.25rem;
    }

    .paginationPage {
        min-width: 32px;
        padding: 6px;
    }
}

.paginationDots {
    padding: 8px;
    color: rgba(255, 255, 255, 0.5);
    min-width: 40px;
    text-align: center;
}

@media (max-width: 480px) {
    .paginationDots {
        min-width: 32px;
        padding: 6px;
    }
}

.transactions .fieldLabel {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 10px;
}

.showTransactionsButton {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--theme-color-text);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: opacity 0.2s;
}

.showTransactionsButton:hover {
    opacity: 0.9;
}

.showTransactionsButton:active {
    opacity: 0.8;
}

.tabs {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-bottom: 20px;
    padding: 0 0 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tab {
    padding: 8px 16px;
    background: transparent;
    border: none;
    color: var(--theme-color-text);
    font-size: 16px;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s;
    position: relative;
    flex: 1;
}

.tab:hover {
    opacity: 0.9;
}

.tab.active {
    opacity: 1;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--theme-color-text);
}

.comingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: var(--theme-color-text);
    font-size: 18px;
    opacity: 0.7;
}

.leaderboardContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.currentUser {
    background: rgba(255, 193, 7, 0.1);
}

.currentUser td {
    font-weight: 500;
}

/* Обновим стили таблицы для лучшего отображения ранга */
.table th:first-child,
.table td:first-child {
    width: 80px;
    text-align: center;
}

/* Обновим стили таблицы для лучшего отображения кредитов */
.table th:last-child,
.table td:last-child {
    width: 100px;
    text-align: right;
}

.multiplierBadge {
    background: linear-gradient(135deg, #f5a623, #ff8c00);
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    margin: 10px 0;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 166, 35, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(245, 166, 35, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(245, 166, 35, 0);
    }
}

.bonusReward {
    display: block;
    color: #f5a623;
    font-weight: bold;
    margin-top: 4px;
    font-size: 14px;
}

/* Add these new styles for the subscription button */

.subscribeContainer {
    background: linear-gradient(135deg, #1e3a3d, #234547);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    margin: 10px 0;
    text-align: center;
}

.subscribeContainer p {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 0 10px 0 !important;
    font-weight: 400 !important;
}

.subscribeButton {
    background: linear-gradient(135deg, #FFC107, #ff8c00);
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.subscribeButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.subscribeButton:active {
    transform: translateY(0);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

/* Add these new styles for the subscription containers */

.multiplierContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

/* Modify the diamond badge to have a different color */
.multiplierBadge span {
    display: inline-block;
    width: 100%;
}

.tier-diamond.multiplierBadge {
    background: linear-gradient(135deg, #9c27b0, #673ab7);
}

/* Add this CSS class for the reward breakdown */
.rewardBreakdown {
    display: inline-block;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 5px;
    font-style: italic;
}

/* If you need to adjust the activity item credits class */
.activityItemCredits {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}