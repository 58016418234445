.background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    background: #0a282c;
    background: linear-gradient(180deg, #0a282c 45%, #145056 100%);
    overflow: hidden;
    z-index: 1;
}

.top {
    position: absolute;
    height: 60%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: transparent;
    background: radial-gradient(circle, rgba(8, 224, 195, 0.15) 10%, transparent 70%);
    right: -30%;
}

.middle {
    position: absolute;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: transparent;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 10%, transparent 70%);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bottom {
    position: absolute;
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: transparent;
    background: radial-gradient(circle, rgba(152, 8, 224, 0.15) 10%, transparent 70%);
    left: -20%;
    bottom: -50%;
}