.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 550px;
    padding: 100px 20px 50px 20px;
    overflow-x: scroll;
    box-sizing: border-box;
}
.button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

textarea:disabled, button:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.close {
    position: absolute;
    top: calc(var(--tg-safe-area-inset-top) + 50px);
    right: 10px;
}

.spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    top: calc(50% + 50px);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.spinnerOverlay span {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}