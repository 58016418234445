.post {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 0px;
  margin-bottom: 40px;
  width: 100%;
  min-width: 340px;
  max-width: 450px;
  box-sizing: border-box;
  color: var(--theme-text-color);
  overflow: visible;
  position: relative;
}

.post::after {
    content: '';
    position: absolute;
    bottom: -26px;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
}

.postHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 300;
}

.post.hasMessages .postHeader {
    max-height: 450px !important;
    overflow: hidden;
}

.post.expanded .postHeader {
    max-height: none !important;
}

.postAuthor {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* .previewImage.blurred {
  filter: blur(20px);
  transition: filter 0.3s ease;
} */

/* .blurredContainer {
    position: relative;
    filter: blur(20px);
    transition: filter 0.3s ease;
} */

.nsfwWarning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  color: white;
  pointer-events: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.nsfwWarningText {
  background: rgba(0, 0, 0, 0.7) ;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  text-align: center;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.imageContainer {
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  width: 100%;
  min-height: 480px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authorInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
  z-index: 200;
}

.authorName {
  color: var(--theme-text-color);
  font-size: 1rem;
}

.authorName a {
  color: var(--theme-text-color);
  text-decoration: none;
}

.postDate {
  color: var(--tg-theme-hint-color);
  font-size: 0.7rem;
}

.postContent {
  color: var(--theme-text-color);
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 12px 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 0 12px;
}

.postContent p {
    margin: 5px 0;
    padding: 0;
}

.postActions {
  display: flex;
  gap: 10px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 20px;
  transition: all 0.2s ease;
  color: var(--theme-text-color);
}

.actionButton svg {
    width: 16px;
    height: 16px;
}

.actionButton:hover {
}

.actionButton.liked {
}

.actionButton span {
  font-size: 1.1rem;
}

.characterInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;

    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
}

.characterNameContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: opacity 0.2s;
}

.infoIcon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    color: var(--theme-text-color);
}

.characterInfoHeader {
    padding: 5px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 600;
    box-sizing: border-box;
    position: relative;
}

.characterInfoHeader * {
    z-index: 1;
}

.characterInfoHeader::after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    content: '';
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.characterBio {
    font-size: 0.8rem;
}

.followButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 10px;
    transition: all 0.2s ease;
    color: var(--theme-text-color);
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0.1);
}

.reactionsWrapper {
  position: relative;
}

.shareButton,
.reactionButton,
.favoriteButton,
.addCommentButton {
    width: 60px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--theme-text-color);
    transition: all 0.2s ease;
    font-size: 1.5rem;
    padding: 8px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: none !important;
}

.shareButton {
    position: absolute;
    right: 16px;
    z-index: 150;
}

@media (hover: hover) {
    .shareButton:hover,
    .reactionButton:hover,
    .favoriteButton:hover,
    .addCommentButton:hover {
        background: none;
        background-color: none;
    }
}

@media (hover: none) {
    .shareButton:active,
    .reactionButton:active,
    .favoriteButton:active,
    .addCommentButton:active {
        background: none;
        background-color: none;
    }
}

.shareButton svg,
.reactionButton svg,
.favoriteButton svg,
.addCommentButton svg {
    transition: all 0.2s ease;
    width: 24px;
    height: 24px;
}

@media (hover: hover) {
    .shareButton:hover svg,
    .reactionButton:hover svg,
    .favoriteButton:hover svg,
    .addCommentButton:hover svg {
        transform: scale(1.2);
    }
}

@media (hover: none) {
    .shareButton:active svg,
    .reactionButton:active svg,
    .favoriteButton:active svg,
    .addCommentButton:active svg {
        transform: scale(1.2);
    }
}

.reactionButton {
  display: flex;
  align-items: center;
  gap: 6px;
}

.addCommentButton {
    display: flex;
    align-items: center;
    gap: 4px;
}

.reactionsListSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
}

.post.hasMessages .reactionsList {
    display: none;
}

.post.hasMessages .reactionsList.show {
    display: flex;
}

.reactionsList {
  position: absolute;
  bottom: 100%;
  right: 25px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 5px;
  box-shadow: 0 4px 12px #00000033;
  z-index: 100;
  font-size: 0.8rem;
  max-height: 340px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.reactionsList::-webkit-scrollbar {
  display: none;
}

.scrollTopButton {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  width: 100%;
  padding: 8px;
  color: var(--theme-text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 8px;
  margin-top: auto;
}

.scrollTopButton:hover {
  background: rgba(0, 0, 0, 0.9);
}

.scrollTopButton svg {
  width: 20px;
  height: 20px;
}

.reactionOption {
  background: none;
  border: none;
  color: var(--theme-text-color);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
  min-width: 40px;
  min-height: 40px;
}

.reactionOption:hover {
  transform: scale(1.1);
}

.reactionOption.active {
    font-weight: 600;
    color: var(--theme-button-secondary-color);
}

.reactionIcon {
  width: 20px;
  height: 20px;
}

.reactionCount {
  font-size: 0.8rem;
  min-width: 20px;
  text-align: center;
}

.activeReaction {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1.2rem;
}

.reactionEmoji {
  font-size: 1rem;
  line-height: 1;
}

.messagesContainer {
  display: flex;
  gap: 8px;
  padding: 16px 16px 70px 16px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.messagesContainer::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: local;
    opacity: 0.8;
    z-index: -1;
    background-image: var(--background-image);
    filter: blur(5px);
}
