.cropAvatar {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cropAvatar .avatarChangeButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
}

.cropAvatar .button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.cropAvatar .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.cropAvatar .button.upload {
    margin-top: 10px;
    padding: 10px 20px;
}

.cropAvatar .button.upload:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}