.chats {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
}

.chats.macos.fullscreen,
.chats.macos {
    padding-top: 20px;
}

.chats.macos {
    padding-top: 20px;
}

.chats.fullscreen {
    padding-top: 90px;
}

.chats .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    padding: 20px 10px 50px 10px;
    box-sizing: border-box;
    height: 100vh;
}
/* 
@media (min-width: 600px) {
    .chats .container {
        padding: 0px 50px 100px 50px;
    }
} */

.chats .avatar {
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.5);
    margin: 0 10px 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.chats .chatsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
}

.chats .chat {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 75px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 12px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: background-color 0.2s ease;
}

.chats .chat:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.chats .chat .avatar {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    border-radius: 16px;
    background-size: cover;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chats .chat .info {
    margin-left: 12px;
    gap: 4px;
}

.chats .chat .info .chatName {
    font-weight: 600;
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.95);
    margin-bottom: 2px;
}

.chats .chat .info .chatName,
.chats .chat .info .botName,
.chats .chat .info .messages {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chats .chat .actions {
    margin-left: auto;
    padding-left: 8px;
    display: flex;
    gap: 4px;
}

.chats .chat .actions .button {
    padding: 12px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.2s ease;
}

.chats .chat .actions .button:hover {
    color: rgba(255, 255, 255, 1);
}

.chats .chat .messages {
    margin-top: 5px;
}

.chats .footer {
    width: 100%;
    max-height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chats .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.chats .footerTabs {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 850px;
}

.chats .footerTabs button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.chats .footerTabs button:active {
    opacity: 0.5;
}

.chats .footerTabs button svg {
    font-size: 1.5rem;
    color: #FFF;
}

.chatsList {
    overflow-y: auto;
    height: calc(100vh - 60px);
    padding-bottom: 60px;
}

.chatInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.6);
}

.chatInfo .messages {
    display: flex;
    align-items: center;
    gap: 5px;
}

.chatInfo .messages svg {
    font-size: 1.1em;
    opacity: 0.8;
}

.date {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.85em;
    margin-top: 5px;
}

.loading {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 280px;
}

.loading > div {
    height: 100%;
}

.empty {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding: 32px 16px;
    font-size: 1.1em;
}

.chats .earnButton {
    position: relative;
}

.chats .earnButton svg {
    height: 34px;
    width: 34px;
}

.chats .earnButton label {
    background-color: #FFC107;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 25px;
}

.newChatButton {
    background-color: #FFC107;
    color: #3c2d00;
    padding: 15px 25px;
    border: 0;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    align-self: center;
    margin-bottom: 70px;
    margin-top: 20px;
}

.lastMessage {
    font-size: 0.85rem;
    color: #888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    max-width: 100%;
}

.noMessages {
    font-style: italic;
    color: #aaa;
}