.field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0 10px 0;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.field textarea {
    padding: 10px 10px 30px 10px;
}

.generate {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background: linear-gradient(to bottom, #5938E3, #A600FF);
    font-size: 0.8rem;
    font-weight: 400;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 2px 10px;
}

.error {
    border: 1px solid red;
}

.errorMessage {
    color: red;
    font-size: 0.8rem;
    margin-left: 5px;
}

.counter {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
}

.text {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 5px;
}