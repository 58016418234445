.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  font-family: "Rubik", sans-serif;
  height: calc(var(--tg-viewport-stable-height) + var(--tg-safe-area-inset-bottom));
}

.modal {
  background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 16px;
  max-width: 650px;
  box-sizing: border-box;
  animation: slideUp 0.3s ease-out forwards;
  color: var(--theme-text-color);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.modalHeader {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.modalHeader h3 {
  margin: 0;
  font-size: 16px;
}

.closeButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: var(--theme-text-color);
}

.modalContent {
  flex: 1;
  overflow-y: auto;
  max-height: 60vh;
  padding: 16px 0;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modalContent::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.comment {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.commentAuthor {
  font-weight: 600;
  margin-right: 6px;
}

.commentDate {
  color: #8e8e8e;
  font-size: 12px;
}

.commentText {
  margin: 0;
  line-height: 1.4;
}

.commentText a {
  color: var(--theme-text-color);
  text-decoration: none;
  font-weight: 600;
}

.modalFooter {
  padding-top: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.addCommentButton {
  width: 100%;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--theme-text-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}

.addCommentButton:hover {
  background: rgba(255, 255, 255, 0.15);
}

.loader {
  text-align: center;
  padding: 20px 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}

.replies {
  margin-left: 14px;
  margin-top: 8px;
  padding-left: 8px;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
}

.reply {
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.reply:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.commentTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.replyButton {
  background: none;
  border: none;
  padding: 4px 8px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.2s;
}

.replyButton:hover {
  color: var(--theme-text-color);
} 

.replyTo {
    color: #8e8e8e;
    font-size: 0.8rem;
    font-weight: 300;
    margin-left: 10px;
}

.commentFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    padding-top: 8px;
}

.commentReplies {
    color: #8e8e8e;
    font-size: 0.8rem;
    font-weight: 300;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
}

.replyText {
    font-size: 0.7rem;
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
}