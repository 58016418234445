.create {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
}

.create .blur {
    filter: blur(4px);
}

.create.macos.fullscreen,
.create.macos {
    padding-top: 20px;
}

.create.macos {
    padding-top: 30px;
}

.create.fullscreen {
    padding-top: 90px;
}

.create.keyboardOpen {
    padding-bottom: 200px;
}

.create .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.create .overlay span {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}

.create .spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.create .spinnerOverlay span {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}

.create .spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    top: calc(50% + 80px);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.create .create {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.create .create button {
    width: 70px;
    font-size: 0.9rem;
    margin-top: 0;
    padding: 10px 0;
}

.create button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.create.macos .create {
    margin-top: 20px;
}

.create .container {
    padding: 10px 10px 170px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.create .button.upload {
    margin-top: 10px;
    padding: 10px 20px;
}

.create .button.upload:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.create .name {
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
}

.create .author {
    font-size: 0.9rem;
}

.create .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.create .avatar {
    width: 100%;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 5px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.create .avatarEdit {
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 30px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create .avatar:active {
    opacity: 0.9;
}

.create .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
    gap: 5px;
    font-size: 1.1rem;
    width: 100%;
    box-sizing: border-box;
}

.create .stats div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    border-radius: 5px;
    position: relative;
    flex: 1;
    cursor: pointer;
}

.create .stats div:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.create .stats div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 30px;
    width: 1px;
    position: absolute;
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
}

.create .stats div span {
    margin-left: 5px;
}

.create .bio {
    font-size: 0.95rem;
    padding: 0 5px;
    display: block;
}

.create .button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.create .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.create .menu {
    width: 90%;
    max-width: 550px;
    position: absolute;
    z-index: 9999;
    bottom: -100vh;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(to right, rgb(0, 28, 29), rgb(0, 42, 43));
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px 15px 0 0;
    padding: 0px 10px 30px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: bottom 0.3s ease-in-out;
}

.create .menu.menuOpen {
    bottom: -10px;
}

.create .menu .menuTitle {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    width: 100%;
    text-align: center;
}

.create .menu .button:last-child {
    border-bottom: none;
}

.create .menu .bot {
    width: 100%;
}

.create .menu .button {
    width: 100% !important;
    min-width: 100% !important;
    border-radius: 0 !important;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: #FFFFFF;
    font-size: 1rem;
    margin: 0;
    padding: 20px 0;
}

.create .button.close {
    border: none !important;
}

.create .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
}

.create .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
}

.create .tab.active {
    background-color: rgba(255, 255, 255, 0.2);
}

.create .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.create .tabs div span {
    margin-left: 5px;
}

.create .field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0 10px 0;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.create .counter {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
}

.create input,
.create textarea {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 10px 10px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.create .fakeTextarea {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 5px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.create .fakeTextarea textarea {
    background: transparent;
    border: none;
    outline: none;
    min-height: 40px;
}

.create textarea {
    min-height: 100px;
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
}

.create .text {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 5px;
}

.create .visability {
    width: 100%;
}

.create .visability button {
    padding: 10px 20px;
    margin-top: 0;
}

.create .visability.is_active button {
    background-color: #FFC107;
    color: #000;
}

.create .tags {
    width: 100%;
}

.create .tag {
    font-size: 0.9rem;
    line-height: 25px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 25px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px;
    padding: 2px 10px 2px 10px;
}

.create .style {
    margin-bottom: 20px;
}

.create .tokens span:first-child, .style span:first-child {
    margin-left: 0;
    background-color: transparent;
    padding: 0;
}

.create .tokens span, .style span {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 10px;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    font-size: 0.8em;
}

.create .tokens span:active, .style span:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.create .crop {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.create .avatarChangeButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.create .avatarChangeButtons button {
    width: 50%;
    margin-top: 10px;
    padding: 10px 0;
}

.create .avatarChangeButtons button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.create .moderation_score {
    font-size: 0.9rem;
}

.create .moderation_score > div {
    margin: 10px 0;
}

.create .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.create .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.create .error, .create .success {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;

    z-index: 99999;
    color: rgba(255, 255, 255, 1);
    padding: 10px;

    animation: popup 1s;
}

@keyframes popup {
    0% {
        transform: translateY(-100%) translateX(-50%);
    }
    100% {
        transform: translateY(0) translateX(-50%);
    }
}

.create .error div, .create .success div {
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.create .error div {
    background-color: rgba(225, 0, 0, 1);
}

.create .success div {
    background-color: rgba(0, 180, 0, 1);
}

.create .alert {
    color: rgb(221, 255, 0);
}

.create .warning {
    color: rgba(255, 165, 0, 1);
}

.create .danger {
    color: rgba(255, 0, 0, 1);
}

.create a {
    color: #FFC107;
    text-decoration: underline;
}

.create .moderationGuide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7);
}

.create .moderationGuideContent {
    padding: 10px 10px 170px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 550px;
    width: 100%;
    box-sizing: border-box;
}

.create .moderationGuideHeader {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.create .blur {
    filter: blur(10px);
}

.create .dialogueUser {
    flex-direction: row-reverse;
}

.create .dialogueCharacter {
    flex-direction: row;
}

.create .dialogueUser .counter {
    right: 50px;
}

.create .dialogueUser,
.create .dialogueCharacter {
    gap: 10px;
}

.create .dialogueCharacter textarea,
.create .dialogueUser textarea {
    min-width: auto;
}

.create .userAvatar {
    background: linear-gradient(135deg, #37aee2, #1e96c8);
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create .characterAvatar {
    background: linear-gradient(135deg, #e2ce37, #c8761e);
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create .dialogue {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.create .addDialogue,
.create .addMemory {
    padding: 10px 20px;
    margin-top: 10px;
}

.create .memory {
    width: 100%;
}

.create .importContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    right: 10px;
    top: 10px;
    gap: 10px;
    z-index: 1;
}

.create .importOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.create .importOverlay span,
.create .importOverlay p {
    color: #fff;
    font-size: 0.8rem;
    margin: 10px 0 0 0;
    text-align: left;
}

.create .importContainer button {
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 5px;
}

.create .importContainer > button:first-of-type {
    background: linear-gradient(to bottom, #5938E3, #A600FF);
    color: #fff;
}

.create .importContainer button {
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 5px;
}

.create .importContainer button.import {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: none;
    cursor: pointer;
}

.create .importContainer .importOptions {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.create .importContainer .importOptions button {
    cursor: pointer;
}

.create .importContainer .importOptions.importOptionsOpen {
    display: flex;
}

.create .importContainer .importOptions button {
    width: 100%;
    border-radius: 0;
}

.create .importContainer .importOptions button:first-child {
    border-radius: 5px 5px 0 0;
}

.create .importContainer .importOptions button:last-child {
    border-radius: 0 0 5px 5px;
}

.create .importContainer .importOptions button:only-child {
    border-radius: 5px;
}

.create .importContainer .importOptions button:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.create .importUrl {
    max-width: 550px;
    width: 100%;
    position: relative;
}

.create .importUrl input {
    width: 100%;
    margin-bottom: 10px;
}

.close {
    position: absolute;
    top: calc(var(--tg-content-safe-area-inset-top) + 50px);
    right: 10px;
}

.modalField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 550px;
}