.blur {
    filter: blur(10px);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 550px;
    padding: 100px 20px 50px 20px;
    overflow-x: scroll;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.question {
    font-size: 1.2rem;
    font-weight: 600;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    margin: 10px;
}

.button:active,
.button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fieldLabel {
    font-size: 1rem;
    font-weight: 600;
}

.browsingLevel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-width: 300px;
    margin-top: 20px;
}

.browsingLevel div {
    flex: 1;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    font-size: 0.9rem;
}

.browsingLevel div:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.browsingLevel div.active {
    background-color: rgba(255, 255, 255, 0.2);
}

.browsingLevel div:nth-child(2n) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.browsingLevel div:first-child {
    border-radius: 5px 0 0 5px;
}

.browsingLevel div:last-child {
    border-radius: 0 5px 5px 0;
    border-right: none;
}

.footer {
    margin-top: 20px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
}

.footer a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: underline;
}
