.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  font-family: "Rubik", sans-serif;
  height: calc(var(--tg-viewport-stable-height) + var(--tg-safe-area-inset-bottom));
}

.colors {
  --basic: #4A90E2;
  --premium: #FFD700;
  --diamond: #50E3C2;
}

.paywall {
  font-family: "Rubik", sans-serif;
  font-weight: 100;
  background: #0F0F0F;
  border-radius: 16px 16px 0 0;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  padding: 16px;
  max-width: 650px;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
  animation: slideUp 0.3s ease-out forwards;
  overflow-x: hidden;
}

.manageSubscription {
  color: var(--tier-color);
  text-decoration: underline;
  cursor: pointer;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  left: 16px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 17px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.closeButton span {
  display: flex;
  align-items: center;
}

.header {
  text-align: center;
  margin: 40px 0 24px;
}

.header h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 4px;
  text-transform: uppercase;
  color: #8774E1;
}

.limitMessage {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  margin: 0;
}

.planContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 4px;
  scrollbar-width: none; /* For Firefox */
}

.planContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.plan {
  padding: 12px;
  background: #1C1C1D;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 12px;
  border: 2px solid transparent;
  min-width: 60px;
  max-width: 140px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
}

.plan:hover {
  background: #2C2C2D;
}

.plan.selected {
  background: rgba(135, 116, 225, 0.1);
  border-color: var(--tier-color);
  box-shadow: 0 0 10px rgba(135, 116, 225, 0.2);
}

.planName {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 6px;
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.radio {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--tier-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .radio {
    opacity: 0;
  }
}

.radioInner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--tier-color);
  opacity: 0;
}

.radioInner.selected {
  opacity: 1;
}

.selected .radioInner {
  opacity: 1;
}

.discount {
  color: var(--tier-color);
  font-size: 14px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 8px;
  border-radius: 10px;
  margin-bottom: 4px;
  display: inline-block;
}

.originalPrice {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: line-through;
  font-size: 14px;
}

.price {
  font-size: 20px;
  font-weight: 600;
  margin: 4px 0;
  color: #fff;
}

.perMonth {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.credits {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  padding: 6px 0;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

mark {
  background: none;
  font-weight: 600;
  color: var(--tier-color);
  font-size: 1.5rem;
}

.features {
  margin: 24px 0;
}

.feature {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  background: #1C1C1D;
  margin-bottom: 2px;
  border-radius: 10px;
}

.feature p {
  margin: 0;
}

.feature p:first-child {
  margin-top: 10px;
}

.featureIcon {
  font-size: 24px;
  margin-right: 12px;
  width: 32px;
  text-align: center;
}

.featureInfo {
  flex: 1;
}

.featureTitle {
  font-size: 15px;
  margin-bottom: 2px;
}

.featureDescription {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  line-height: 1.4;
}

.subscribeButton {
  width: 100%;
  padding: 16px;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
  background-color: var(--tier-color);
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .paywall {
    padding: 16px;
  }
}

/* Add these new styles */
.paymentMethods {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 24px 0;
}

.paymentMethod {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #1C1C1D;
  border: none;
  width: 100%;
  color: #fff;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s;
  position: relative;
}

.selectedPaymentMethod {
  background: rgba(135, 116, 225, 0.1);
  box-shadow: 0 0 10px rgba(135, 116, 225, 0.2);
  border: 2px solid var(--tier-color);
}

.paymentMethod:first-child {
  border-radius: 10px 10px 0 0;
}

.paymentMethod:last-child {
  border-radius: 0 0 10px 10px;
}

.paymentIcon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: #2C2C2D;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: #ffbf00;
}

.paymentInfo {
  flex: 1;
}

.paymentTitle {
  font-size: 17px;
  margin-bottom: 4px;
}

.paymentDescription {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}

.tierTabs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 8px;
}

.tierTab {
  padding: 10px 18px;
  border-radius: 20px;
  background-color: #1C1C1D;
  color: rgba(255, 255, 255, 0.7);
  border: 2px solid transparent;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
  text-align: center;
  max-width: 120px;
  position: relative;
}

.tierTab:hover {
  background-color: #2C2C2D;
  color: rgba(255, 255, 255, 0.9);
}

.activeTier {
  background-color: rgba(135, 116, 225, 0.1);
  color: #fff;
  border: 2px solid;
  position: relative;
  box-shadow: 0 0 10px rgba(135, 116, 225, 0.2);
}

.tierHeader {
  margin-bottom: 16px;
  text-align: center;
}

.tierHeader h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.tierDescription {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin: 0;
}

.selectedPlanInfo {
  text-align: center;
  margin-bottom: 20px;
  padding: 16px;
  background: rgba(28, 28, 29, 0.5);
  border-radius: 12px;
}

.creditsInfo {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}

.buyStarsLink {
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
  color: var(--tier-color);
  font-size: 16px;
  text-decoration: underline;
}

.buyStarsLink:hover {
  opacity: 0.8;
}

.instructionModal {
  font-family: "Rubik", sans-serif;
  font-weight: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

.instructionContent {
  background: #1C1C1D;
  border-radius: 16px;
  padding: 48px 24px 24px 24px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  color: #fff;
  margin: 20px;
}

.instructionTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--tier-color);
  text-align: center;
}

.instructionText {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.instructionStep {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.stepNumber {
  background: var(--tier-color);
  color: #000;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-right: 12px;
  flex-shrink: 0;
}

.stepText {
  flex: 1;
}

.closeInstructionButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.instructionImage {
  width: 100%;
  border-radius: 8px;
  margin: 16px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.instructionNote {
  background: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 16px;
  border-left: 3px solid var(--tier-color);
}

.ctaButton {
  background: var(--tier-color);
  color: #fff;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
  text-decoration: none;
}

a.ctaButton {
  text-decoration: none;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}

.loadingText {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  margin-top: 24px;
  font-weight: 300;
}

.loadingSpinner {
  width: 48px;
  height: 48px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--tier-color, #4a90e2);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

.loadingDots {
  display: flex;
  gap: 6px;
  margin-top: 8px;
  justify-content: center;
}

.loadingDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--tier-color, #4a90e2);
  opacity: 0.6;
  animation: pulse 1.4s ease-in-out infinite;
}

.loadingDot:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.loadingSkeleton {
  width: 100%;
  background: #1C1C1D;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
}

.loadingSkeleton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.05) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.tierLoadingSkeleton {
  height: 50px;
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.tierLoadingItem {
  height: 100%;
  flex: 1;
  max-width: 120px;
  border-radius: 20px;
  background: #1C1C1D;
  position: relative;
  overflow: hidden;
}

.planLoadingSkeleton {
  height: 100px;
}

.methodSection {
  margin: 24px 0;
}

.methodSection h3 {
  font-size: 1.1em;
  margin-bottom: 16px;
  color: var(--tier-color);
}

.buttonGroup {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.secondaryButton {
  background-color: transparent !important;
  border: 2px solid var(--tier-color);
  color: var(--tier-color);
}

.secondaryButton:hover {
  background-color: rgba(var(--tier-color-rgb), 0.1) !important;
}

.currentPlan {
  padding: 12px;
  background: rgba(var(--tg-theme-secondary-bg-color), 0.5);
  border-radius: 8px;
  font-size: 14px;
}

.currentPlan p {
  margin: 0;
  line-height: 1.4;
}

.currentPlan strong {
  color: var(--tier-color);
}

/* Add these styles to PayWall.module.css */

.upgradeBenefits {
  background: #151516;
  border-radius: 12px;
  padding: 16px;
  margin: 24px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.upgradeTitle {
  font-size: 18px;
  font-weight: 600;
  color: var(--tier-color);
  margin: 0 0 16px 0;
  text-align: center;
}

.benefitCalculation {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}

.calcRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.calcLabel {
  display: flex;
  flex-direction: column;
}

.calcDetail {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 2px;
}

.calcValue {
  font-weight: 500;
}

.calcDivider {
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 8px 0;
}

.benefitCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
}

.benefitCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.benefitIcon {
  font-size: 20px;
  margin-bottom: 8px;
}

.benefitInfo {
  flex: 1;
}

.benefitTitle {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
}

.benefitValue {
  font-size: 16px;
  font-weight: 600;
  color: var(--tier-color);
  margin-bottom: 4px;
}

.benefitCompare, 
.benefitDetail {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .benefitCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

.currentActivePlan {
  opacity: 0.75;
  position: relative;
  pointer-events: none;
  border: 2px solid rgba(var(--tier-color), 0.3) !important;
  cursor: default;
}

.currentActivePlan::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.currentPlanBadge {
  position: absolute;
  top: 5px;
  right: 5px;
  background: var(--tier-color);
  color: #000;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 10px;
  z-index: 1;
}

.disabledTier {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
  position: relative;
  overflow: visible;
}

.tierNoteLabel {
  position: absolute;
  top: -20px;
  right: 50%;
  transform: translateX(50%);
  background: rgba(255, 255, 255, 0.2);
  font-size: 9px;
  padding: 2px 5px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  z-index: 999;
}

/* Best Subscription Message */
.bestSubscriptionMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  border-radius: 16px;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 40px 0;
  animation: fadeIn 0.6s ease-out;
}

.crownIcon {
  font-size: 48px;
  margin-bottom: 16px;
  animation: float 3s ease-in-out infinite;
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.6));
}

.bestTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
  background: linear-gradient(90deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 215, 0, 0.2);
}

.bestSubscriptionMessage p {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.featureShowcase {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  margin: 8px 0 20px;
}

.showcaseItem {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  padding: 12px 16px;
  transition: transform 0.2s, background 0.2s;
}

.showcaseItem:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.12);
}

.showcaseIcon {
  font-size: 24px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.showcaseText {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.showcaseText > div:first-child {
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.showcaseDetail {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 2px;
}

.expiryInfo {
  background: rgba(255, 255, 255, 0.08);
  padding: 14px;
  border-radius: 12px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  box-sizing: border-box;
}

.expiryDate {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 6px;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Coming Soon Feature Styling */
.comingSoonFeature {
  position: relative;
  opacity: 0.75;
  background: rgba(255, 255, 255, 0.05);
  transition: opacity 0.2s ease;
}

.comingSoonFeature:hover {
  opacity: 1;
}

.comingSoonBadge {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 9px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 10px;
  background: linear-gradient(135deg, #FF6B6B, #FFB88C);
  color: #fff;
  vertical-align: middle;
  text-transform: uppercase;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 107, 107, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 107, 107, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 107, 107, 0);
  }
}

/* Congratulation Modal Styles */
.congratulationModal {
  position: relative;
  width: 90%;
  max-width: 500px;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.815), rgba(0, 0, 0, 0.383));
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Confetti Animation */
.confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.confettiPiece {
  position: absolute;
  width: 8px;
  height: 16px;
  background-color: var(--color);
  /* Position in center of container */
  top: 50%;
  left: 50%;
  opacity: 0;
  /* Make the animation infinite for continuous explosions */
  animation: confettiExplode 3s ease-out var(--delay) infinite;
}

@keyframes confettiExplode {
  0% {
    opacity: 0;
    transform: translate(0, 0) rotate(0deg) scale(0.3);
  }
  5% {
    opacity: 1;
    transform: translate(0, 0) rotate(0deg) scale(0.5);
  }
  20%, 30% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
    /* The outward movement and direction will be defined in inline styles */
    transform: 
      translate(var(--x, 100px), var(--y, 100px))
      rotate(var(--rotation, 360deg))
      scale(0);
  }
}

/* Congratulation Content */
.congratsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.congratsIcon {
  font-size: 48px;
  margin-bottom: 16px;
  animation: float 3s ease-in-out infinite;
  filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.6));
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.congratsTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
  background: linear-gradient(90deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 215, 0, 0.2);
}

.congratsMessage {
  font-size: 16px;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  max-width: 90%;
}

/* Enhanced Congratulation Details */
.congratsDetails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 16px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 12px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.08);
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  transition: transform 0.2s, background 0.2s;
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
}

.detailItem:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-2px);
}

.detailIcon {
  font-size: 28px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.detailInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailTitle {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4px;
}

.detailValue {
  font-size: 22px;
  font-weight: 700;
  color: #FFD700;
  background: linear-gradient(90deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2px;
}

.detailUnit {
  font-size: 12px;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 2px;
}

/* Expiry Information */
.congratsExpiry {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.08);
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 90%;
}

.expiryIcon {
  font-size: 20px;
}

.expiryText {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.expiryDate {
  margin-left: 6px;
  font-weight: 600;
  color: #FFD700;
}

/* Enhance the animation for the congratulation icon */
.congratsIcon {
  font-size: 60px;
  margin-bottom: 24px;
  animation: partyPulse 2s infinite alternate;
  filter: drop-shadow(0 0 15px rgba(255, 215, 0, 0.6));
}

@keyframes partyPulse {
  0% { transform: scale(1) rotate(-5deg); }
  50% { transform: scale(1.1) rotate(0deg); }
  100% { transform: scale(1) rotate(5deg); }
}

/* CTA Button for Congratulation Modal */
.congratsContent .ctaButton {
  background: linear-gradient(90deg, #FFD700, #FFA500);
  color: #000;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  width: 80%;
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
}

.congratsContent .ctaButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 215, 0, 0.4);
}

.congratsContent .ctaButton:active {
  transform: scale(0.98) translateY(0);
}

/* Close Button */
.congratulationModal .closeButton {
  max-width: 80px;
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  transition: all 0.2s;
}

.congratulationModal .closeButton:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

/* New loading styles */
.loadingSubscriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  margin: 1rem 0;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: 240px;
}

.loadingIcon {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: pulse 2s infinite ease-in-out;
}

.loadingContent {
  width: 100%;
}

.loadingTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--tier-color, #a667ff);
  margin-bottom: 0.5rem;
}

.loadingDescription {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.loadingAnimation {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 1rem;
}

.loadingDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--tier-color, #a667ff);
  animation: loadingDots 1.4s infinite ease-in-out both;
}

.loadingDot:nth-child(1) {
  animation-delay: -0.32s;
}

.loadingDot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDots {
  0%, 80%, 100% { 
    transform: scale(0);
  }
  40% { 
    transform: scale(1);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
} 