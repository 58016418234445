.loraContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
}

.loraItem, .currentLoraItem {
    position: relative;
}

.loraItem a, .currentLoraItem a {
    text-decoration: none;
}

.loraItem, .currentLoraItem, .selectedLoraItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    max-height: 200px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.selectedLoraItem {
    border: 1px dotted #fff;
    background-color: rgba(255, 255, 255, 0.2);
    max-height: none;
    overflow: visible;
}

.loraItemImages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.loraItem div:first-child,
.currentLoraItem div:first-child,
.selectedLoraItem div:first-child {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.loraItem img,
.currentLoraItem img,
.selectedLoraItem img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.search input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #fff;
    height: 40px;
}

.search button {
    padding: 10px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    cursor: pointer;
    height: 40px;
}

.spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    position: absolute;
    top: calc(50% + 50px);
    left: calc(50% - 24px);
    transform: translate(-50%, -50%);
    z-index: 99999;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.spinnerOverlay span {
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: 10px 0;
    text-align: center;
}

.deleteButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
    height: 34px;
    width: 34px;
    z-index: 99999;
}

.deleteButton svg {
    color: #FFFFFF;
    font-size: 1.5rem;
}