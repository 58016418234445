.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
}

.avatar {
    width: 100%;
    max-width: 185px;
    max-height: 185px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.4);
    border: 5px solid rgba(255, 255, 255, 0.4);
    margin: 20px 0 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.visability {
    position: absolute;
    top: 10px;
    left: 10px;
}

.name {
    font-size: 1rem;
    display: block;
    margin: 5px 0;
}

.description {
    display: block;
    font-size: 0.8em;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tags {
    margin: 10px 0;
}

.tag {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    height: 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 2px;
    padding: 3px 10px;
    font-size: 0.7em;
}

.stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.9rem;
}

.stats div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.stats div span {
    margin-left: 5px;
}

.isAdult {
    position: absolute;
    top: 10px;
    left: 30px;
}

.isAdult span {
    position: absolute;
    top: 0;
    left: 19px;
    font-size: 0.7rem;
    font-weight: 400;
    display: block;
    height: 16px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    min-height: 16px;
    max-height: 16px;
    overflow: hidden;
}

.rating {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: 400;
}

.rating.XXX {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.X {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.R {
    background-color: rgba(255, 0, 0, 0.4);
}

.rating.PG {
    background-color: rgba(0, 0, 255, 0.4);
}

.rating.PG_13 {
    background-color: rgba(0, 255, 0, 0.4);
}
